import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

import SliderReviewQuotes from './SliderReviewQuotes';
import useAdjustedHeadingSize from '../useAdjustedHeadingSize';

const ReviewsPdp = ({ reviews = [], title = 'The Reviews' }) => {
  if (reviews.length === 0) {
    return null;
  }
  const h1Style = useAdjustedHeadingSize(1);
  return (
    <Box fill="horizontal" border={{ side: 'bottom', size: 'small' }}>
      <Box
        fill="horizontal"
        pad="medium"
        border={{ side: 'bottom', size: 'small' }}
        align="center"
      >
        <Heading level={2} style={h1Style}>
          {title}
        </Heading>
      </Box>
      <Box pad={{ vertical: 'medium' }}>
        <SliderReviewQuotes reviews={reviews} />
      </Box>
    </Box>
  );
};

ReviewsPdp.propTypes = {
  title: PropTypes.string,
  reviews: PropTypes.array,
};

export default ReviewsPdp;
